import { Badge } from 'antd'
import { useAuth } from 'contexts/auth'
import colors from 'helpers/colors'
import { urls } from 'helpers/urls'
import { zIndex } from 'helpers/zIndex'
import { useCartOrder } from 'hooks/orders'
import { useUnReadMessageCount } from 'hooks/users'
import { sum } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import CartIcon from 'public/icons/cart.svg'
import HomeIcon from 'public/icons/home.svg'
import MailIcon from 'public/icons/mail.svg'
import MemberIcon from 'public/icons/member.svg'
import { VFC } from 'react'
import styled from 'styled-components'

import { Avatar } from '../Avatar'
import { AccountMenuDropdown } from '../Header/account'

export const BottomNavigation: VFC = () => {
  const router = useRouter()
  const { data: order } = useCartOrder()
  const { data: unReadMessageCount } = useUnReadMessageCount()
  const { isAuthenticated, user, setIsSignInOpened } = useAuth()

  const handleSignIn = () => {
    setIsSignInOpened(true)
  }

  const linkToLoggedInPage = (path: string) => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      return
    }

    router.push(path)
  }

  return (
    <div
      style={{
        zIndex: zIndex.bottomNavigation,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background: 'white',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        borderTop: `1px solid ${colors.grey}`,
      }}
    >
      <div style={{ flex: 1, padding: '12px 16px' }}>
        <Link href={urls.top}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <HomeIcon style={{ width: 24, height: 24, fontSize: 24 }} />
          </div>
        </Link>
      </div>
      <div style={{ flex: 1, padding: '12px 16px' }}>
        <a onClick={() => linkToLoggedInPage('/messages')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <StyledBadgeWrapper style={{ height: 24 }}>
              <Badge count={unReadMessageCount || 0} size="small">
                <MailIcon style={{ width: 24, height: 24, fontSize: 24 }} />
              </Badge>
            </StyledBadgeWrapper>
          </div>
        </a>
      </div>
      <div style={{ flex: 1, padding: '12px 16px' }}>
        <a onClick={() => linkToLoggedInPage('/account/orders/cart')}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <StyledBadgeWrapper style={{ height: 24 }}>
              <Badge
                count={
                  order
                    ? sum(order.art_work_variant_orders.map((v) => v.quantity))
                    : 0
                }
                size="small"
              >
                <CartIcon style={{ width: 24, height: 24, fontSize: 24 }} />
              </Badge>
            </StyledBadgeWrapper>
          </div>
        </a>
      </div>
      <div style={{ flex: 1, padding: '12px 16px' }}>
        {isAuthenticated && user ? (
          <AccountMenuDropdown placement="topLeft">
            <a
              onClick={(e) => e.preventDefault()}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <StyledBadgeWrapper style={{ height: 20 }}>
                  <StyledAvatar
                    src={user.image_url}
                    size="small"
                    isFallBackAnonymous={false}
                  >
                    {user.nickname.slice(0, 1)}
                  </StyledAvatar>
                </StyledBadgeWrapper>
              </div>
            </a>
          </AccountMenuDropdown>
        ) : (
          <a onClick={handleSignIn}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <MemberIcon style={{ fontSize: 24, width: 24, height: 24 }} />
            </div>
          </a>
        )}
      </div>
    </div>
  )
}

const StyledBadgeWrapper = styled.div`
  font-size: 10px;
  .ant-badge-count-sm {
    background-color: ${colors.red};
    font-size: 10px;
  }
`

const StyledAvatar = styled(Avatar)`
  &.ant-avatar-sm {
    height: 24px;
    width: 24px;
    background-color: black;
    align-items: center;
  }
`
