import api from 'api'
import useSWR from 'swr'

/**
 * 国情報を取得するSWR
 */
export const useCountries = () => {
  return useSWR<Country[]>(
    '/static/countries',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
