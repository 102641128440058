import {
  DEFAULT_DESCRIPTION,
  DEFAULT_IMAGE,
  DEFAULT_SITE_NAME,
  DEFAULT_TITLE,
  DEFAULT_TYPE,
} from 'helpers/ogp'
import type { NextPage } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'

interface Props {
  title?: string
  thumbnailUrl?: string
  description?: string
  type?: 'article' | 'website'
  siteName?: string
}

export const NextHead: NextPage<Props> = ({
  title = DEFAULT_TITLE,
  thumbnailUrl = DEFAULT_IMAGE,
  description = DEFAULT_DESCRIPTION,
  type = DEFAULT_TYPE,
  siteName = DEFAULT_SITE_NAME,
}) => {
  const router = useRouter()
  return (
    <Head>
      <link rel="icon" href="/favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <title>{title}</title>
      <meta property="og:title" content={title} key="title" />
      <meta property="og:image" content={thumbnailUrl} key="image" />
      <meta
        property="og:url"
        content={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}${router.pathname}`}
        key="url"
      />
      <meta property="og:description" content={description} key="description" />
      <meta property="og:type" content={type} key="type" />
      <meta property="og:site_name" content={siteName} key="site_title" />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter_title"
      />
      <meta name="twitter:image" content={thumbnailUrl} key="twitter_image" />
    </Head>
  )
}
