/**
 * urlsをまとめるファイル
 */
export const urls = {
  top: '/',
  auction: '/auction/top',
  artists: '/artists',
  art_work: (id: string) => `/art_works/${id}`,
  art_works: '/art_works',
  event: (eventId: string) => `/events/${eventId}`,
  events: '/events',
  owner: (id: string, hash?: string) =>
    `/owners/${id}${hash ? `#${hash}` : ''}`,
  owners: '/owners',
  magazine: (id: string) => `/magazines/${id}`,
  magazines: '/magazines',
  owner_art_work: (ownerId: string, artWorkId: string) =>
    `/owners/${ownerId}/art_works/${artWorkId}`,
  owner_art_works: (ownerId: string) => `/owners/${ownerId}/art_works`,
  owner_art_event: (ownerId: string, eventId: string) => `/events/${eventId}`,
  owner_art_events: (ownerId: string) => `/owners/${ownerId}/events`,
  owner_art_artists: (ownerId: string) => `/owners/${ownerId}/artists`,
  topic: '/topics',
  account: (path?: string) => (path ? `/account/${path}` : '/account'),
  messages: '/messages',
  lotteries: '/lotteries',
  lottery: (lotteryId?: string) =>
    lotteryId ? `/lotteries/${lotteryId}` : '/404',

  lp: '/about/cloud',

  lp_case: '/about/cloud/case',
  lp_case_details: (id: string) => `/about/cloud/case/${id}`,
  // TODO: 資料請求用のurlができたら差し替える
  document_request_for_lp: '/about/cloud/document_dl',
  contact_for_lp: '/about/cloud/contact',
  help_center_for_lp: 'https://help.cloud.artx.com',
}
