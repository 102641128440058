/**
 * 言語に応じて作品の説明を返す関数
 */
export const getArtWorkDescription = (
  artWork: Pick<ArtWork, 'description' | 'description_en'>,
  langType: string,
): string | undefined => {
  if (langType === 'en' && artWork?.description_en)
    return artWork.description_en
  // フォールバック先は description
  return artWork.description
}
