import { message } from 'antd'
import api from 'api'
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react'

/**
 * 問い合わせを作成するカスタムフック
 * @returns
 */
export const useCreateInquiry = () => {
  const [isSendingRequest, setIsSendingRequest] = useState(false)
  const router = useRouter()

  const createInquiry = useCallback(
    async (artWorkId: string, text: string) => {
      setIsSendingRequest(true)
      const { data, error }: { data: { message_room_id: string }; error: any } =
        await api.post('/orders/create_inquiry', {
          message_text: text,
          art_work_id: artWorkId,
        })
      if (data) {
        router.push(`/messages/${data.message_room_id}`)
      }
      if (error) {
        message.error(error.data.error)
      }
      setIsSendingRequest(false)
    },
    [router],
  )

  return { isSendingRequest, createInquiry }
}
