import { Slider } from 'antd'
import { SliderRangeProps } from 'antd/lib/slider'
import { VFC } from 'react'
import styled from 'styled-components'

export const StyledSlider: VFC<SliderRangeProps> = ({ ...rest }) => {
  return <StyledSliderWrapper {...rest} />
}

const StyledSliderWrapper = styled(Slider)`
  margin: 11px;

  .ant-slider-track {
    height: 2px;
    border-radius: 40px;
    background-color: #232427;
  }

  .ant-slider-rail {
    height: 1px;
    border-radius: 40px;
    background-color: #c4c4c4;
  }

  .ant-slider-handle {
    width: 20px;
    height: 20px;
    top: -0.5px;
    border: 1px solid var(--ax-gray-3, #c4c4c4);
    background-color: var(--AX--WHITE, #ffffff);
    box-shadow: 0 0 0 1px var(--ax-gray-3, #e0e0e0);
  }
`
