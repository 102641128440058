/**
 * 言語に応じて作品の分類技法自由記入を返す関数
 */
export const getArtWorkClassificationOtherStr = (
  artWork: Pick<
    ArtWork,
    'classification_other_str' | 'classification_other_str_en'
  >,
  langType: string,
): string | undefined => {
  if (langType === 'en' && artWork?.classification_other_str_en)
    return artWork.classification_other_str_en
  // フォールバック先は classification_other_str
  return artWork.classification_other_str
}
