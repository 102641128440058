import { Typography } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { urls } from 'helpers/urls'
import { Categories, FollowingArticle } from 'libs/contentfulForMagazine'
import moment from 'moment'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { VFC } from 'react'
import styled from 'styled-components'

import { FollowButtonS } from './FollowButton'

type Props = {
  title: string
  postId: string
  slug?: string
  tags: Categories[]
  setSelectedCategory?: (tag: Categories) => void
  publishedAt: Date
  imageUrl?: string
  titleRows: number
  followStatus?: FollowingArticle
  sideBySide?: boolean
}

export const MagazineCard: VFC<Props> = ({
  title,
  postId,
  followStatus,
  slug = postId,
  tags,
  publishedAt,
  imageUrl,
  setSelectedCategory,
  titleRows,
  sideBySide = false,
}) => {
  const { t } = useTranslation()
  const { xs } = useBreakpoint()

  return (
    <div style={{ width: xs ? '100%' : 270, minWidth: xs ? 159 : 270 }}>
      <Link href={urls.magazine(postId)} as={urls.magazine(slug)} passHref>
        <a style={{ display: 'flex' }}>
          <FlexContainer
            className="MagazineCard box"
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              flex: '1 0 0',
              width: '100%',
            }}
          >
            <FlexContainer
              className="Image box"
              style={{
                height: xs && sideBySide ? 100 : 162,
                backgroundImage: `url('${
                  imageUrl
                    ? `${imageUrl}?fit=thumb&q=50&w=360`
                    : '/no-image.svg'
                }')`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundColor: 'lightgray',
                alignItems: 'flex-end',
                gap: '-33px',
                position: 'relative',
              }}
            >
              {followStatus && (
                <div style={{ position: 'absolute', right: '8px', zIndex: 3 }}>
                  <FollowButtonS
                    followStatus={followStatus}
                    style={{ width: '24px', height: '24px' }}
                  />
                </div>
              )}
            </FlexContainer>
            <FlexContainer
              style={{
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <FlexContainer
                className="Category Date"
                style={{
                  alignItems: 'center',
                  gap: '4px',
                }}
              >
                <FlexContainer
                  style={{
                    flex: '1 0 0',
                    flexDirection: xs && sideBySide ? 'column' : 'row',
                    alignItems: 'baseline',
                  }}
                >
                  {tags.map((tag) => (
                    <a
                      key={tag}
                      style={{
                        color: 'var(--ax-gray-4, #A5A4A8)',
                        fontSize: '12px',
                        lineHeight: 1.5,
                        marginRight: 4,
                        whiteSpace: 'nowrap',
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        setSelectedCategory && setSelectedCategory(tag)
                      }}
                    >
                      {t(tag)}
                    </a>
                  ))}
                </FlexContainer>
                <Typography.Text
                  style={{
                    color: 'var(--ax-gray-4, #A5A4A8)',
                    fontSize: '12px',
                    textAlign: 'right',
                  }}
                >
                  {moment(publishedAt).format('YYYY.MM.DD')}
                </Typography.Text>
              </FlexContainer>

              <Link href={postId} key={postId} passHref>
                <a>
                  <Typography.Title
                    style={{
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                    ellipsis={{ rows: titleRows }}
                  >
                    {title}
                  </Typography.Title>
                </a>
              </Link>
            </FlexContainer>
          </FlexContainer>
        </a>
      </Link>
    </div>
  )
}
const FlexContainer = styled.div`
  display: flex;
  align-self: stretch;
`
