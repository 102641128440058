import api from 'api'
import useSWR from 'swr'

/**
 * idをもとにArtistをフォローされてるか取得するSWR
 */
export const useIsFollowing = (id: string) => {
  return useSWR<boolean>(
    id ? `/artists/${id}/follow_status` : null,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
