import api from 'api'
import useSWR, { mutate } from 'swr'

const generateKey = (id: string) => `/orders/${id}`

/**
 * カート情報を取得するSWR
 */
export const useOrder = (id: string) => {
  return useSWR<Order>(
    id ? generateKey(id) : null,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}

export const mutateOrder = (id: string) => {
  mutate(generateKey(id))
}
