import { message } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { ArtWorkCard } from 'components/models/artworks'
import { EventCard } from 'components/models/events/EventCard'
import { MagazineCard } from 'components/models/magazines'
import { OwnerCard } from 'components/models/owners'
import { List } from 'components/pages/root'
import { Section } from 'components/pages/root/Section'
import { TopContent } from 'components/pages/root/TopContent'
import { BottomNavigation } from 'components/shared/BottomNavigation'
import { Button } from 'components/shared/Button'
import { Footer } from 'components/shared/Footer'
import { GlobalNoScrollbarWrapper } from 'components/shared/GlobalNoScrollbarWrapper'
import { Header } from 'components/shared/Header'
import { useAuth } from 'contexts/auth'
import { useHomeArtworks } from 'hooks/artworks'
import { useHomeEvents } from 'hooks/events'
import { useIsFollowings, useMagazines } from 'hooks/magazines'
import { useHomeOwners } from 'hooks/owners/useHomeOwners'
import { ArticleCard, Categories, Entry } from 'libs/contentfulForMagazine'
import { GetServerSideProps } from 'next'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { VFC } from 'react'
import styled from 'styled-components'

type Props = {
  events: EventCardType[]
  owners: OwnerCardType[]
  artWorks: ArtWorkCardType[]
  artists: ArtistCardType[]
  magazines: Entry<ArticleCard>[]
}

const Home: VFC<Props> = () => {
  const { xs } = useBreakpoint()
  const { t, i18n } = useTranslation()
  const { isAuthenticated, setIsSignUpOpened } = useAuth()
  const { data: events } = useHomeEvents()
  const { data: owners } = useHomeOwners()
  const { data: artWorks } = useHomeArtworks()
  const { data: magazinesData = [] } = useMagazines(
    {
      locale: i18n.language,
      limit: 10,
    },
    {},
  )
  const magazines = magazinesData.flat() || []

  const { data: followStatus, error: followError } = useIsFollowings(
    magazines
      ? magazines.map((data: { fields: { slug: any } }) => data.fields.slug)
      : [],
  )

  if (followError) {
    message.error('お気に入りを取得できませんでした')
  }
  return (
    // 本来はDefaultLayoutを使うべきだが横幅を超えたスクロールを実装するためにこのコンポーネントで直接パーツを表示している
    <GlobalNoScrollbarWrapper>
      <Header onlyLogo={false} />
      {events && magazines && (
        <TopContent events={events} magazines={magazines} />
      )}
      <div
        style={{
          paddingRight: xs ? 16 : 64,
          paddingLeft: xs ? 16 : 64,
          marginBottom: xs ? 80 : 110,
          overflow: 'hidden',
        }}
      >
        {events && events.length > 0 && (
          <div style={{ marginTop: xs ? 0 : 80 }}>
            <Section title={t('おすすめのイベント')} link="/events">
              <List>
                {events.map((event, index) => (
                  <EventCard key={index} event={event} />
                ))}
              </List>
            </Section>
          </div>
        )}
        {artWorks && artWorks.length > 0 && (
          <div style={{ marginTop: xs ? 60 : 100 }}>
            <Section title={t('おすすめの作品')} link="/art_works">
              <List>
                {artWorks.map((artWork, index) => (
                  <ArtWorkCard key={index} artWork={artWork} sideBySide />
                ))}
              </List>
            </Section>
          </div>
        )}
        {owners && owners.length > 0 && (
          <div style={{ marginTop: xs ? 60 : 150 }}>
            <Section title={t('おすすめのギャラリー')} link="/owners">
              <List>
                {owners.map((owner, index) => (
                  <OwnerCard key={index} owner={owner} addressType="short" />
                ))}
              </List>
            </Section>
          </div>
        )}
        {magazines && magazines.length > 0 && (
          <div style={{ marginTop: xs ? 60 : 100 }}>
            <Section title={t('おすすめのマガジン')} link="/magazines">
              <List>
                {magazines.map(({ fields, sys, metadata }, index) => (
                  <div
                    key={index}
                    style={{
                      width: xs ? 160 : 270,
                      minWidth: xs ? 160 : 270,
                      alignSelf: 'flex-start',
                    }}
                  >
                    <MagazineCard
                      key={index}
                      title={fields.title}
                      postId={sys.id}
                      slug={fields.slug}
                      tags={metadata.tags.map(
                        (tag) => tag.sys.id as Categories,
                      )}
                      publishedAt={fields.publishedAt}
                      imageUrl={
                        fields.mainImage?.fields.file.url || '/no-image/4.svg'
                      }
                      titleRows={xs ? 3 : 2}
                      followStatus={
                        followStatus
                          ? followStatus.find(
                              ({ slug }) => slug === fields.slug,
                            )
                          : undefined
                      }
                      sideBySide
                    />
                  </div>
                ))}
              </List>
            </Section>
          </div>
        )}
        {/* NOTE: アーティスト詳細画面が実装されるまでコンポーネント自体を非表示にする */}
        {/* {(artists && artists.length > 0) && (
          <div style={{ marginTop: xs ? 60 : 128 }}>
            <Section title={t("おすすめのアーティスト")} link='/artists'>
              <List>
                {artists.map((artist, index) => (
                  <ArtistCard key={index} artist={artist} />
                ))}
              </List>
            </Section>
          </div>
        )} */}
      </div>

      {!isAuthenticated && (
        <div style={{ width: '100%', height: 372, position: 'relative' }}>
          <CoverDiv
            style={{
              backgroundImage: xs
                ? 'url("/footer-bg-sp.png")'
                : 'url("/footer-bg.png")',
            }}
          />
          <div
            style={{
              position: 'relative',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              padding: xs ? '60px 48px' : '60px 20px',
              maxWidth: '1280px',
              margin: '0 auto',
              zIndex: 2,
            }}
          >
            <div style={{ marginBottom: 40 }}>
              <Image src={'/logo-white.svg'} height={40} width={100} />
            </div>
            <div style={{ marginBottom: 24 }}>
              <p
                style={{ color: 'white', textAlign: 'center', lineHeight: 1.6 }}
              >
                {t(
                  'ArtXは、アートでくらしを豊かにする情報をお届けしています。',
                )}
                {xs ? undefined : <br />}
                {t('無料登録をすると、プレミアム記事の閲覧も可能になります。')}
              </p>
            </div>
            <Button
              onClick={() => setIsSignUpOpened(true)}
              type="secondary"
              style={{
                width: xs ? '100%' : 270,
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              {t('会員登録する')}
            </Button>
          </div>
        </div>
      )}
      <Footer onlyLogo={false} />
      {xs && <BottomNavigation />}
    </GlobalNoScrollbarWrapper>
  )
}

const CoverDiv = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  background-size: cover;
`

export default Home

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'ja', ['common'])),
    },
  }
}
