import { Alert, Button, Image, Modal, ModalProps, Typography } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { numberWithDelimiter } from 'helpers/numberWithDelimiter'
import { getArtWorkTitle } from 'libs/artWorks/getArtWorkTitle'
import { map } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import AlertIcon from 'public/icons/alert.svg'
import ArrowRightIcon from 'public/icons/arrow/next.svg'
import CloseIcon from 'public/icons/close.svg'
import { FC } from 'react'
import styled from 'styled-components'

export type CartAddArtworkModel = {
  artWork: ArtWork
  title: string
  quantity: number
  total: number
}

type Props = {
  error?: string
  data: CartAddArtworkModel | undefined
  onClose: () => void
}

export const ArtworkCartAddModal: FC<ModalProps & Props> = ({
  visible,
  error,
  data,
  onClose,
}) => {
  const { xs } = useBreakpoint()
  const router = useRouter()
  const { t, i18n } = useTranslation()

  if (!error && visible) {
    setTimeout(() => onClose(), 5000)
  }

  return (
    <Modal
      centered
      title={
        <Typography.Text style={{ fontWeight: 700, fontSize: 16 }}>
          {t(
            error
              ? '商品をカートに入れられませんでした'
              : 'カートに追加しました',
          )}
        </Typography.Text>
      }
      closeIcon={
        <CloseIcon style={{ height: 16, width: 16, cursor: 'pointer' }} />
      }
      footer={null}
      bodyStyle={{ overflow: 'auto', padding: 0 }}
      visible={visible}
      width={xs ? 343 : 465}
      onCancel={onClose}
    >
      <div style={{ padding: '16px 24px 24px' }}>
        <div style={{ borderBottom: '1px solid #ECECEC' }}>
          {error && (
            <div style={{ marginBottom: 24 }}>
              <StyledAlert
                showIcon
                icon={
                  <AlertIcon
                    style={{ height: 20, marginRight: '4px' }}
                    fill="#E95353"
                  />
                }
                type="error"
                message={
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      lineHeight: 1.5,
                      color: '#E95353',
                    }}
                  >
                    {error}
                  </Typography.Text>
                }
              />
            </div>
          )}

          {data && (
            <div
              style={{
                padding: '16px 0px',
                display: 'flex',
                flexDirection: xs ? 'column' : 'row',
                alignItems: xs ? 'end' : 'center',
                justifyContent: 'space-between',
                rowGap: '4px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flex: 1,
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    flex: 1,
                  }}
                >
                  <Image
                    src={
                      data.artWork.main_image_thumb_contain_url ||
                      data.artWork.main_image_url ||
                      ''
                    }
                    style={{
                      height: 100,
                      width: 100,
                      objectFit: 'contain',
                      backgroundColor: '#ECECEC',
                    }}
                  />
                  <div
                    style={{
                      marginLeft: '8px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      flex: 1,
                    }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: 16,
                        lineHeight: '24px',
                        fontWeight: 'bold',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {getArtWorkTitle(data.artWork, i18n.language)}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {map(data.artWork.artists, 'name').join('/')}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {data.artWork.owner.name}
                    </Typography.Text>
                    <Typography.Text
                      style={{
                        fontSize: 12,
                        lineHeight: '18px',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      {t('種類')}：{data.title}
                    </Typography.Text>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: xs ? 'column' : 'row',
                  alignItems: xs ? 'end' : 'center',
                  justifyContent: 'space-between',
                  rowGap: '8px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography.Text
                    style={{
                      fontWeight: 700,
                      fontSize: '16px',
                      lineHeight: 1.5,
                      marginBottom: xs ? 8 : 10,
                    }}
                  >
                    ×{data.quantity}
                  </Typography.Text>
                  <Typography.Text
                    style={{
                      marginLeft: '24px',
                      fontSize: '16px',
                      lineHeight: 1.5,
                      fontWeight: 700,
                      textAlign: 'right',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    JPY {numberWithDelimiter(data.total || 0)}
                  </Typography.Text>
                </div>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
            marginTop: '24px',
          }}
        >
          <Button
            style={{
              width: '100%',
              borderRadius: '40px',
              height: '48px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: 'black',
              borderColor: 'black',
            }}
            type="primary"
            onClick={() => router.push('/account/orders/cart')}
          >
            <Typography.Text
              style={{
                fontSize: 16,
                lineHeight: 1,
                color: 'white',
                fontWeight: 700,
              }}
            >
              {t('カートへ進む')}
            </Typography.Text>
            <ArrowRightIcon
              style={{ height: '16px', width: '16px', marginLeft: '8px' }}
              fill="white"
            />
          </Button>
          <Link href="/art_works">
            <a
              style={{
                textAlign: 'center',
                textDecoration: 'underline',
                color: 'black',
              }}
            >
              {t('買い物を続ける')}
            </a>
          </Link>
        </div>
      </div>
    </Modal>
  )
}

const StyledAlert = styled(Alert)`
  &.ant-alert-error {
    background: #ffdfdb;
    border: 1px #e95353 solid;
    padding: 8px 12px;
    align-items: flex-start;

    svg {
      path:nth-child(even) {
        fill: #e95353;
      }
      rect {
        fill: #e95353;
      }
    }
  }
`
