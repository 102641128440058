// eslint-disable-next-line no-restricted-imports
import { Modal as OriginalModal } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import CloseIcon from 'public/icons/close.svg'
import { ComponentProps, ReactNode, VFC } from 'react'

import { Spinner } from '../Spinner'

type Props = {
  children: ReactNode
  loading: boolean
} & ComponentProps<typeof OriginalModal>

export const Modal: VFC<Props> = ({ style, children, loading, ...props }) => {
  const { xs } = useBreakpoint()
  return (
    <OriginalModal
      {...props}
      style={{
        ...style,
        top: xs ? '50%' : 80,
        transform: xs ? 'translate(0, -50%)' : undefined,
      }}
      closeIcon={<CloseIcon style={{ height: 16, width: 16 }} />}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            height: '272px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        children
      )}
    </OriginalModal>
  )
}
