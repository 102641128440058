/**
 * 言語に応じて名前を返す関数
 */
export const getArtistName = (
  artist: Pick<Artist, 'name' | 'name_en'>,
  langType: string,
): string => {
  if (langType === 'en' && artist?.name_en) return artist.name_en
  // フォールバック先は name
  return artist.name
}
