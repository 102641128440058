import api from 'api'
import useSWR from 'swr'

/**
 * slugをもとに記事がお気に入り登録されてるか取得するSWR
 */
export const useIsFollowing = (slug: string) => {
  return useSWR<boolean>(
    slug ? `/magazines/${slug}/follow_status` : null,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
