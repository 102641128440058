import api from 'api'
import useSWR from 'swr'

type PhoneCountryCodeType = {
  id: string
  country_code: string
}

/**
 * 電話番号の国際番号を取得するSWR
 */
export const usePhoneCountryCodes = () => {
  return useSWR<PhoneCountryCodeType[]>(
    '/static/phone_country_codes',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
