import { SearchOutlined } from '@ant-design/icons'
import { Badge, Dropdown, Menu, Select, Typography } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { useAuth } from 'contexts/auth'
import colors from 'helpers/colors'
import { urls } from 'helpers/urls'
import { zIndex } from 'helpers/zIndex'
import { useCartOrder } from 'hooks/orders'
import { useSwitchLanguage } from 'hooks/shared/useSwitchLanguage'
import { useUnReadMessageCount } from 'hooks/users'
import { sum } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import ArrowDownIcon from 'public/icons/arrow/down.svg'
import CartIcon from 'public/icons/cart.svg'
import MailIcon from 'public/icons/mail.svg'
import MemberIcon from 'public/icons/member.svg'
import WorldIcon from 'public/icons/world.svg'
import { useMemo, useState, VFC } from 'react'
import styled from 'styled-components'

import { Avatar } from '../Avatar'
import { AccountMenuDropdown } from './account/AccountMenuDropdown'
import { HeaderForSp } from './HeaderForSp'

type Props = {
  onlyLogo?: boolean
}

export const Header: VFC<Props> = ({ onlyLogo }) => {
  const router = useRouter()
  const { xs } = useBreakpoint()
  const { isAuthenticated, user, setIsSignInOpened } = useAuth()
  const { data: order } = useCartOrder()
  const { data: unReadMessageCount } = useUnReadMessageCount()
  const { t, i18n } = useTranslation()
  const { switchLanguage } = useSwitchLanguage()

  const [searchText, setSearchText] = useState<string>()

  const handleSignIn = () => {
    setIsSignInOpened(true)
  }

  const linkToLoggedInPage = (path: string) => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      return
    }

    router.push(path)
  }

  const menuItems = useMemo(() => {
    const ja = i18n.language === 'ja'
    return [
      {
        link: urls.events,
        text: t('イベント'),
      },
      {
        link: urls.art_works,
        // FIXME: 作品のenがArtWorkになっているのでハードコーディングしている
        // 翻訳ファイルの分割方針を決めてファイルを分けた後に修正する
        text: ja ? '作品' : 'Works',
      },
      {
        // FIXME: 作品のenがGalleryになっているのでハードコーディングしている
        // 翻訳ファイルの分割方針を決めてファイルを分けた後に修正する
        link: urls.owners,
        text: ja ? 'ギャラリー' : 'Galleries',
      },
      {
        link: urls.magazines,
        text: t('マガジン'),
      },
      {
        link: urls.auction,
        text: t('オークション'),
      },
    ]
  }, [i18n.language, t])

  if (xs) return <HeaderForSp onlyLogo={onlyLogo} />

  if (onlyLogo) {
    return (
      <div
        style={{
          borderBottom: `1px solid ${colors.border}`,
          top: 0,
          zIndex: zIndex.header,
          position: 'sticky',
          background: 'white',
          backgroundColor: 'white',
        }}
      >
        <div
          style={{
            height: '80px',
            display: 'flex',
            margin: '0 auto',
            columnGap: '40px',
            paddingRight: 64,
            paddingLeft: 64,
            maxWidth: 1280,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Link href="/">
            <a>
              <Image src={'/logo.svg'} height={35.75} width={88} />
            </a>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        borderBottom: `1px solid ${colors.border}`,
        top: 0,
        zIndex: zIndex.header,
        position: 'sticky',
        background: 'white',
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          display: 'flex',
          margin: '0 auto',
          columnGap: 24,
          padding: '20px 64px',
          maxWidth: 1280,
          flexDirection: 'row',
          alignItems: 'center',
          overflowX: 'scroll',
        }}
      >
        <Link href="/">
          <a style={{ height: 35.75, minWidth: 88 }}>
            <Image src={'/logo.svg'} height={35.75} width={88} />
          </a>
        </Link>
        <StyledInputSearch
          showSearch
          value={searchText !== '' ? searchText : undefined}
          onChange={() => router.push(`/search?q=${searchText}`)}
          onSearch={(value) => setSearchText(value)}
          options={
            searchText
              ? [
                  {
                    label: t('{{searchText}}で検索する', { searchText }),
                    value: 'search',
                  },
                ]
              : undefined
          }
          notFoundContent={null}
          filterOption={false}
          suffixIcon={null}
          bordered={false}
          placeholder={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <SearchOutlined
                size={16}
                style={{ color: colors.black, marginRight: '8px' }}
              />
              <Typography.Text
                style={{
                  color: colors.grey30,
                  marginRight: '8px',
                  fontSize: '14px',
                }}
              >
                {t('検索したいキーワードを入力')}
              </Typography.Text>
            </div>
          }
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            gridGap: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              whiteSpace: 'nowrap',
            }}
          >
            {menuItems.map((menuItem, index) => {
              const isActive = router.pathname === menuItem.link
              return (
                <div key={index} style={{ padding: '11px 12px' }}>
                  <Link href={menuItem.link}>
                    <a>
                      <Typography
                        style={{
                          fontSize: 12,
                          lineHeight: isActive ? 1 : 1.5,
                          fontWeight: isActive ? 'bold' : 'normal',
                        }}
                      >
                        {t(menuItem.text)}
                      </Typography>
                    </a>
                  </Link>
                </div>
              )
            })}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gridGap: '16px',
              height: '40px',
            }}
          >
            <a onClick={() => linkToLoggedInPage('/messages')}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <StyledBadgeWrapper style={{ height: 20 }}>
                  <Badge count={unReadMessageCount || 0} size="small">
                    <MailIcon style={{ width: 20, height: 20, fontSize: 24 }} />
                  </Badge>
                </StyledBadgeWrapper>
              </div>
            </a>
            <a onClick={() => linkToLoggedInPage('/account/orders/cart')}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <StyledBadgeWrapper style={{ height: 20 }}>
                  <Badge
                    count={
                      order
                        ? sum(
                            order.art_work_variant_orders.map(
                              (v) => v.quantity,
                            ),
                          )
                        : 0
                    }
                    size="small"
                  >
                    <CartIcon style={{ width: 20, height: 20, fontSize: 24 }} />
                  </Badge>
                </StyledBadgeWrapper>
              </div>
            </a>
            {isAuthenticated && user ? (
              <AccountMenuDropdown>
                <a
                  onClick={(e) => e.preventDefault()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <StyledBadgeWrapper style={{ height: 20 }}>
                      <StyledAvatar
                        src={user.image_url}
                        size="small"
                        isFallBackAnonymous={false}
                      >
                        {user.nickname.slice(0, 1)}
                      </StyledAvatar>
                    </StyledBadgeWrapper>
                  </div>
                </a>
              </AccountMenuDropdown>
            ) : (
              <a onClick={handleSignIn}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <MemberIcon style={{ fontSize: 20, width: 20, height: 20 }} />
                </div>
              </a>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={() => switchLanguage('ja')}>
                    {t('JP')}
                  </Menu.Item>
                  <Menu.Item onClick={() => switchLanguage('en')}>
                    {t('EN')}
                  </Menu.Item>
                </Menu>
              }
              trigger={['click']}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                  <div
                    style={{ display: 'flex', gap: 4, alignItems: 'center' }}
                  >
                    <WorldIcon style={{ width: 16, height: 16 }} />
                    <Typography.Text style={{ fontSize: 14, lineHeight: 1 }}>
                      {router.locale === 'ja' ? t('JP') : t('EN')}
                    </Typography.Text>
                  </div>
                  <ArrowDownIcon height={8} width={8} />
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
}

const StyledInputSearch = styled(Select)`
  flex: 1;
  height: 40px;
  border-radius: 3px;
  background-color: #f0f0f0;
`

const StyledBadgeWrapper = styled.div`
  font-size: 10px;
  .ant-badge-count-sm {
    background-color: ${colors.red};
    font-size: 10px;
  }
`

const StyledAvatar = styled(Avatar)`
  &.ant-avatar-sm {
    height: 20px;
    width: 20px;
    background-color: black;
    align-items: center;
  }
`
