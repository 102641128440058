export const DEFAULT_SITE_NAME = 'ArtX'
export const DEFAULT_TITLE = 'ArtX - Connecting Art Lovers Worldwide'
export const DEFAULT_IMAGE = 'https://artx.com/ogimage_ArtX.png'
export const DEFAULT_DESCRIPTION =
  'ArtX is an online platform for art that connects art lovers worldwide. Art collectors, galleries, and artists can buy, sell, and discover artworks.'
export const DEFAULT_TYPE = 'article'

export const TITLE_FOR_LP = 'ArtXCloud | 日本初のギャラリー向け管理システム'
export const IMAGE_FOR_LP = 'https://artx.com/ogimage_ArtXCloud.png'
export const DESCRIPTION_FOR_LP =
  'ArtXCloudは事務作業を最大98%削減できるギャラリー向けのクラウド型管理システムです。作品リストやカタログ、ラベル印刷がたった3ステップで完了できるなど、煩雑な業務からギャラリーを開放します。'
