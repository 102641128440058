import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { FC } from 'react'

type Props = {
  indicatorStyle?: React.CSSProperties
}

export const Spinner: FC<Props> = ({
  indicatorStyle = { fontSize: 48, color: '#000' },
}) => {
  return <Spin indicator={<LoadingOutlined style={indicatorStyle} spin />} />
}
