import api from 'api'
import useSWR from 'swr'

/**
 * Lotteryに表示するアーティストを取得するSWR
 */

export const useArtistsByLotteryId = (lotteryId?: string) => {
  const path = lotteryId ? `/lotteries/${lotteryId}/artists?limit=18` : null
  return useSWR<ArtistNameType[]>(
    path,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
