import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import colors from 'helpers/colors'
import { FC, useMemo, useState } from 'react'

type Props = {
  htmlType?: 'submit'
  style?: any
  size?: 'lg' | 'md' | 'sm'
  type?: 'primary' | 'secondary'
  fontWeight?: 'bold' | 'normal'
  color?: string
  backgroundColor?: string
  disabled?: boolean
  loading?: boolean
} & React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export const Button: FC<Props> = ({
  style,
  size = 'lg',
  type,
  fontWeight,
  htmlType,
  children,
  color,
  backgroundColor,
  disabled,
  loading,
  onClick,
  onMouseEnter,
  onMouseLeave,
  ...rest
}) => {
  const { xs } = useBreakpoint()
  const [isHover, setIsHover] = useState(false)

  const typeStyle = useMemo(() => {
    switch (type) {
      case 'primary':
        if (isHover) {
          return {
            backgroundColor: color || colors.white,
            color: backgroundColor || colors.black,
            borderColor: backgroundColor || colors.black,
          }
        }

        return {
          backgroundColor: backgroundColor || colors.black,
          color: color || colors.white,
          borderColor: backgroundColor || colors.black,
        }
      case 'secondary':
        if (isHover) {
          return {
            backgroundColor: color || colors.black,
            color: backgroundColor || colors.white,
            borderColor: backgroundColor || colors.black,
          }
        }

        return {
          backgroundColor: backgroundColor || colors.white,
          color: color || colors.black,
          borderColor: color || colors.black,
        }
      default:
        return {}
    }
  }, [backgroundColor, color, isHover, type])

  const sizeStyle = useMemo(() => {
    switch (size) {
      case 'lg':
        return {
          paddingRight: xs ? 0 : 60,
          paddingLeft: xs ? 0 : 60,
          paddingTop: 16,
          paddingBottom: 16,
          fontSize: 16,
          fontWeight: fontWeight || 700,
          width: xs ? '100%' : 'auto',
          textAlign: 'center',
        }
      case 'md':
        return {
          paddingRight: 16,
          paddingLeft: 16,
          paddingTop: 12,
          paddingBottom: 12,
          fontSize: 12,
          fontWeight: fontWeight || 700,
        }
      case 'sm':
        return {
          paddingRight: 12,
          paddingLeft: 12,
          paddingTop: 6,
          paddingBottom: 6,
          fontSize: 12,
          fontWeight: fontWeight || 500,
        }
    }
  }, [fontWeight, size, xs])

  const disabledStyle = useMemo(() => {
    return disabled
      ? {
          backgroundColor: '#A5A4A8',
          color: '#FFFFFF',
          borderColor: '#A5A4A8',
          cursor: 'not-allowed',
        }
      : {}
  }, [disabled])

  return (
    <a
      type={htmlType}
      onMouseEnter={(e) => {
        setIsHover(true)
        onMouseEnter && onMouseEnter(e)
      }}
      onMouseLeave={(e) => {
        setIsHover(false)
        onMouseLeave && onMouseLeave(e)
      }}
      style={{
        ...typeStyle,
        ...sizeStyle,
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        lineHeight: '100%',
        borderRadius: 40,
        borderStyle: 'solid',
        borderWidth: '1px',
        textAlign: 'center',
        opacity: 'unset',
        whiteSpace: 'nowrap',
        ...style,
        ...disabledStyle,
      }}
      onClick={disabled ? () => {} : onClick}
      {...rest}
    >
      {loading && (
        <Spin
          style={{ color: 'white', marginRight: '8px' }}
          indicator={<LoadingOutlined spin />}
        />
      )}
      {children}
    </a>
  )
}
