import api from 'api'
import useSWR from 'swr'

/**
 * エントリーを取得するSWR
 */
export const useLottery = (id: string | undefined) => {
  return useSWR<Lottery>(
    id ? `/lotteries/${id}` : null,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
