import { Menu, Typography } from 'antd'
import colors from 'helpers/colors'
import { useTranslation } from 'next-i18next'
import { VFC } from 'react'
import styled, { CSSProperties } from 'styled-components'

import { Avatar } from '../../Avatar'
import { getAccountMenuItems } from './AccountMenuItems'

type Props = {
  user?: User
  style?: CSSProperties
}

export const AccountMenuItem: VFC<Props> = ({ user, style }) => {
  const { t } = useTranslation()
  if (!user) return <></>

  const AccountCard = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          marginBottom: '19px',
        }}
      >
        <Typography.Text
          style={{ lineHeight: 1.5, fontSize: '12px', color: '#4E4E4F' }}
        >
          {t('ログイン中のアカウント')}
        </Typography.Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
            padding: '8px 12px',
          }}
        >
          <StyledAvatar
            size={48}
            src={user.image_url}
            isFallBackAnonymous={false}
          >
            {user.nickname.slice(0, 1)}
          </StyledAvatar>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text style={{ lineHeight: 1.5, fontWeight: 'bold' }}>
              {' '}
              {user.nickname}
            </Typography.Text>
            <Typography.Text
              style={{
                lineHeight: 1.5,
                color: colors.grey30,
                fontSize: '12px',
              }}
            >
              {' '}
              {user.email}
            </Typography.Text>
          </div>
        </div>
      </div>
    )
  }

  return (
    <StyledMenu style={style}>
      <AccountCard />
      {getAccountMenuItems(t).map((item) => (
        <StyledMenuItem itemKey={item.key} key={item.key}>
          {item.label}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  )
}

const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    :hover {
      background-color: #f5f5f5;
    }
  }
`

const StyledMenuItem = styled(Menu.Item)<{ itemKey: string }>`
  ${(props) =>
    props.itemKey.includes('logout')
      ? `
    border-top: 1px solid #ECECEC;
    margin-top: 12px !important;
    padding: 12px 0 !important;
  `
      : `
    height: 34px !important;
    padding: 4px 0 !important;
  `}
`

const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    background-color: black;
    align-items: center;
  }
`
