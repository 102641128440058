/**
 * 言語に応じてタイトルを返す関数
 */
export const getArtWorkTitle = (
  artWork: Pick<ArtWork, 'title' | 'title_en'>,
  langType: string,
): string | undefined => {
  if (langType === 'en' && artWork?.title_en) return artWork.title_en
  // フォールバック先は title
  return artWork.title
}
