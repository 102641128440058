import { Typography } from 'antd'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { SeeMore } from 'components/shared/SeeMore'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import ArrowTopIcon from 'public/icons/arrow/top.svg'
import { Dispatch, useMemo, useState, VFC } from 'react'

import { StyledCheckBoxGroup } from './StyledCheckBoxGroup'
import { StyledSearchInput } from './StyledSearchInput'

type Props = {
  title: string
  selectableObjects: ArtistNameType[] | Technique[] | Classification[]
  checkedIds: CheckboxValueType[]
  setCheckedIds: Dispatch<CheckboxValueType[]>
  searchable?: boolean
  language?: string
}

export const ArtWorkFilterFormItem: VFC<Props> = ({
  title,
  selectableObjects,
  checkedIds,
  setCheckedIds,
  searchable = false,
  language = 'ja',
}) => {
  const [isExpand, setIsExpand] = useState(false)
  const { xs } = useBreakpoint()
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState<string>()
  const filteredObjects = useMemo(
    () =>
      searchText
        ? selectableObjects.filter(
            ({ name, name_en }) =>
              name.toUpperCase().includes(searchText.toUpperCase()) ||
              name_en.toUpperCase().includes(searchText.toUpperCase()),
          )
        : selectableObjects,
    [searchText, selectableObjects],
  )

  const objectName = ({
    name,
    name_en,
  }: {
    name?: string
    name_en?: string
  }) => {
    if (language === 'en' && name_en) return name_en
    return name
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '28px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography.Text
          style={{
            fontSize: '16px',
            lineHeight: 1,
            fontFeatureSettings: "'clig' off, 'liga' off",
            paddingBottom: '20px',
          }}
        >
          {title}
        </Typography.Text>
        {xs && (
          <Link href={'#filter-top'}>
            <a>
              <ArrowTopIcon width={16} height={16} />
            </a>
          </Link>
        )}
      </div>

      {searchable && (
        <div style={{ paddingBottom: '12px' }}>
          <StyledSearchInput
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder={t('{{target}}を検索', { target: title })}
          />
        </div>
      )}

      <StyledCheckBoxGroup
        // もっと見るを押すまでは5つのみ表示する
        options={(isExpand ? filteredObjects : filteredObjects.slice(0, 5)).map(
          (obj) => {
            return { label: objectName(obj), value: obj.id }
          },
        )}
        value={typeof checkedIds === 'string' ? [checkedIds] : checkedIds}
        defaultValue={
          typeof checkedIds === 'string' ? [checkedIds] : checkedIds
        }
        onChange={(values) => setCheckedIds(values)}
        style={{ display: 'flex', flexDirection: 'column', rowGap: '12px' }}
      />
      {selectableObjects.length > 5 && (
        <SeeMore expanded={isExpand} setExpanded={setIsExpand} />
      )}
    </div>
  )
}
