import Colors from 'helpers/colors'
import { StyleSheet, Text, TextProps } from 'react-native'

type CustomTextProps = TextProps

const CustomText: React.FC<CustomTextProps> = ({ style, ...props }) => {
  return (
    <Text
      {...props}
      style={StyleSheet.compose([{ color: Colors.black, fontSize: 14 }], style)}
    />
  )
}

export default CustomText
