import { CheckboxValueType } from 'antd/lib/checkbox/Group'

type QueryObjectType = {
  [x: string]: string | CheckboxValueType[] | undefined
}

export const queryStringParams = (queryObject: QueryObjectType) => {
  const params = new URLSearchParams()

  for (const [key, value] of Object.entries(queryObject)) {
    if (value === undefined || value === '') continue
    if (Array.isArray(value)) {
      const newValue = value.filter((v) => v !== undefined && v !== '')
      if (newValue.length === 0) continue
      newValue.forEach((v) => params.append(key, String(v)))
    } else {
      params.append(key, value)
    }
  }

  return params
}
