import api from 'api'
import useSWR from 'swr'

/**
 * 技法を取得するSWR
 */
export const useTechniques = () => {
  return useSWR<Technique[]>(
    '/static/art_work_techniques',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
