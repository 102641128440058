import { Button } from 'components/shared/Button'
import { useAuth } from 'contexts/auth'
import { useTranslation } from 'next-i18next'
import { useState, VFC } from 'react'
import styled from 'styled-components'

import { InquiryModal } from '../InquiryModal'

type Props = {
  artWorkId: string
}

export const InquiryButton: VFC<Props> = ({ artWorkId }) => {
  const { t } = useTranslation()
  const { isAuthenticated, setIsSignInOpened, setSignInNotification } =
    useAuth()
  const [visible, setVisible] = useState(false)

  const onClickInquiryButton = async () => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      setSignInNotification(
        t('お問い合わせを利用するには、ログインが必要です。'),
      )
      return
    }

    setVisible(true)
  }

  return (
    <>
      <StyledButton
        type="secondary"
        size="sm"
        style={{ fontSize: '12px', fontWeight: 700, gap: '4px' }}
        onClick={(e) => {
          e.preventDefault()
          onClickInquiryButton()
        }}
      >
        <img
          src="/icons/mail.svg"
          style={{ fontSize: '12px', height: '12px', width: '12px' }}
        />
        {t('お問い合わせ')}
      </StyledButton>
      <InquiryModal
        artWorkId={artWorkId}
        visible={visible}
        onCancel={() => setVisible(false)}
      />
    </>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
