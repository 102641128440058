import api from 'api'
import useSWRInfinite from 'swr/infinite'

/**
 * eventIdをもとに作品を取得するSWR
 */
export const useArtworksByEventId = (
  eventId?: string,
  limit?: number,
  search_params?: Record<string, string>,
) => {
  const getKey = (
    pageIndex: number,
    previousPageData: { artWorksData: ArtWorkType[] },
  ) => {
    if (previousPageData && !previousPageData.artWorksData.length) return null
    return {
      path: `/events/${eventId}/art_works`,
      search_params,
      page: pageIndex + 1,
      limit,
    }
  }

  const fetcher = ({
    path,
    search_params,
    page,
    limit,
  }: {
    path: string
    search_params: Record<string, string>
    page: number
    limit?: number
  }) =>
    api
      .get<ArtWorkType[]>(path, {
        params: {
          search_params,
          page,
          limit,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return {
            artWorksData: res.data,
            xTotalCount: res.headers['x-total-count'],
          }
        } else throw res
      })
      .catch((err) => {
        throw err
      })

  return useSWRInfinite(getKey, fetcher, {
    initialSize: 1,
    revalidateOnFocus: false,
  })
}
