import { numberWithDelimiter } from '../../helpers/numberWithDelimiter'

const prefix_mapping = {
  jpy: 'JPY',
  usd: 'USD',
}

const suffix_mapping = {
  jpy: '円',
  usd: 'ドル(USD)',
}

type Args = {
  currency: string
  publish_price?: number | null
  is_available_online?: boolean
  prefix?: boolean
  quantity?: number
  language: string
}

/**
 * Generate price text
 * @param {Args} args
 * @returns string
 */
export const generatePriceText = ({
  currency,
  publish_price,
  is_available_online,
  prefix = true,
  quantity = 1,
  language = 'ja',
}: Args): string => {
  const currency_prefix =
    prefix_mapping[currency as keyof typeof prefix_mapping] || currency
  const currency_suffix =
    suffix_mapping[currency as keyof typeof suffix_mapping]
  const tax_in = language === 'ja' ? '（税込）' : '(Tax In)'

  if (typeof publish_price !== 'number' || !is_available_online) {
    return ''
  }

  const priceText = numberWithDelimiter(publish_price * quantity)
  return prefix
    ? `${currency_prefix} ${priceText} ${tax_in}`
    : `${priceText}${currency_suffix} ${tax_in}`
}
