import api from 'api'
import useSWR from 'swr'

type EventType = Pick<
  Event,
  | 'id'
  | 'start_at'
  | 'end_at'
  | 'title'
  | 'description'
  | 'event_places'
  | 'main_event_image_url'
  | 'active_lottery'
> & {
  owner: {
    name: string
  }
}

type SearchParamsType = {
  title_or_owner_name_or_event_places_name_or_event_places_city_or_event_places_state_cont_any: string[]
  event_places_state_or_event_places_city_cont_any: string[]
  start_at_lteq_any?: string
  end_at_gteq_any?: string
}

/**
 * イベントを取得するSWR
 */
export const useEvents = (search_params: SearchParamsType, limit?: number) => {
  return useSWR<EventType[]>(
    ['/events', search_params, limit],
    (key, search_params, limit) =>
      api
        .get(key, {
          params: {
            search_params,
            limit,
          },
        })
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
