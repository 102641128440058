import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import i18n from 'next-i18next.config.js'
import { useCallback } from 'react'

/*
  言語を切り替えるためのカスタムフック

  - switchLanguage: 言語を切り替える関数
  - switchLanguageOnFirstAccess: 初回アクセス時にブラウザの言語設定を取得して、言語を切り替える関数
*/

const resolveLanguage = (lang: string) => {
  return i18n.i18n.locales.includes(lang) ? lang : i18n.i18n.defaultLocale
}

export const useSwitchLanguage = () => {
  const router = useRouter()

  const switchLanguage = useCallback(
    (lang: string) => {
      // NOTE: queryパラメータを引き継ぐためにasPathを指定する
      const resolvedLang = resolveLanguage(lang)
      Cookies.set('NEXT_LOCALE', resolvedLang, {
        expires: 90,
        secure: true,
        sameSite: 'strict',
      })
      router.push(router.asPath, router.asPath, { locale: resolvedLang })
    },
    [router],
  )

  const switchLanguageOnFirstAccess = useCallback(() => {
    // NOTE: 初回アクセス時にブラウザの言語設定を取得して、言語を切り替える
    if (!router.isReady) return
    const locale = Cookies.get('NEXT_LOCALE')
    if (!locale) {
      const newLocale = window.navigator.language
      const lang = newLocale.substring(0, 2)
      const resolvedLang = resolveLanguage(lang)
      switchLanguage(resolvedLang)
    }
  }, [router, switchLanguage])

  return { switchLanguage, switchLanguageOnFirstAccess }
}
