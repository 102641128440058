/**
 * 言語に応じて経歴を返す関数
 */
export const getArtistBiography = (
  artist: Pick<Artist, 'biography' | 'biography_en'>,
  langType: string,
): string | undefined => {
  if (langType === 'en' && artist?.biography_en) return artist.biography_en
  // フォールバック先は biography
  return artist.biography
}
