import { message } from 'antd'
import api from 'api'
import { useAuth } from 'contexts/auth'
import colors from 'helpers/colors'
import { useIsFollowing } from 'hooks/magazines'
import { useTranslation } from 'next-i18next'
import HeartIcon from 'public/icons/heart.svg'
import { CSSProperties, useEffect, useState, VFC } from 'react'

type Props = {
  style?: CSSProperties
  xs?: boolean
  slug: string
}

export const FollowButtonM: VFC<Props> = ({ slug, style, xs }) => {
  const { t } = useTranslation()
  const { isAuthenticated, setIsSignInOpened, setSignInNotification } =
    useAuth()
  const { data: followStatus } = useIsFollowing(slug)
  const [isFollow, setIsFollow] = useState(followStatus)

  useEffect(() => {
    setIsFollow(followStatus)
  }, [followStatus])

  const onClickFollowButton = async () => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      setSignInNotification(t('お気に入りを利用するには、ログインが必要です。'))
      return
    }

    if (isFollow) {
      const { data, error }: { data: any; error: any } = await api.delete(
        `/magazines/${slug}/unfollow`,
      )
      if (data) {
        message.success(t('お気に入りを解除しました'))
        setIsFollow(false)
      }
      if (error) {
        message.error(error.data.error)
      }
    } else {
      const { data, error }: { data: any; error: any } = await api.post(
        `/magazines/${slug}/follow`,
      )
      if (data) {
        message.success(t('お気に入りに追加しました'))
        setIsFollow(true)
      }
      if (error) {
        message.error(error.data.error)
      }
    }
  }

  return (
    <HeartIcon
      style={{
        ...style,
        color: isFollow ? colors.blue : colors.black,
        opacity: isFollow ? 1 : 0.6,
        width: xs ? '20px' : '22px',
        height: xs ? '20px' : '22px',
        cursor: 'pointer',
      }}
      onClick={(e) => {
        e.preventDefault()
        onClickFollowButton()
      }}
    />
  )
}
