import { Typography } from 'antd'
import { FollowButton } from 'components/models/artists/FollowButton'
import { Avatar } from 'components/shared/Avatar'
import {
  getArtistBiography,
  getArtistDescription,
} from 'libs/getArtistInformation'
import { getArtistName } from 'libs/getArtistName'
import { useMemo, VFC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  artist: Artist
  xs?: boolean
}

export const ArtistDescription: VFC<Props> = ({ artist, xs }) => {
  const { i18n } = useTranslation()
  const name = useMemo(
    () => getArtistName(artist, i18n.language),
    [artist, i18n.language],
  )
  const description = useMemo(
    () => getArtistDescription(artist, i18n.language),
    [artist, i18n.language],
  )
  const biography = useMemo(
    () => getArtistBiography(artist, i18n.language),
    [artist, i18n.language],
  )

  return (
    <div
      style={{
        padding: xs ? 0 : '24px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        border: xs ? 'none' : '1px #ECECEC solid',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '8px',
        }}
      >
        <div style={{ alignItems: 'center', display: 'flex' }}>
          <Avatar size={32} src={artist.image_url} isFallBackAnonymous />
          <Typography.Text
            ellipsis
            style={{
              marginLeft: '12px',
              textDecoration: 'underline',
              whiteSpace: 'pre-line',
            }}
          >
            {name}
          </Typography.Text>
        </div>
        <FollowButton artistId={artist.id} size="md" withUnFollow />
      </div>
      {description && (
        <Typography.Text
          style={{
            whiteSpace: 'pre-line',
            lineHeight: 1.5,
            letterSpacing: '0.8px',
          }}
        >
          {description}
        </Typography.Text>
      )}
      {biography && (
        <Typography.Text
          style={{
            whiteSpace: 'pre-line',
            lineHeight: 1.5,
            letterSpacing: '0.8px',
          }}
        >
          {biography}
        </Typography.Text>
      )}
    </div>
  )
}
