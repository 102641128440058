import { Typography } from 'antd'
import colors from 'helpers/colors'
import { useTranslation } from 'next-i18next'
import { Dispatch, VFC } from 'react'

type Props = {
  expanded: boolean
  setExpanded: Dispatch<boolean>
  blackTheme?: boolean
}

export const SeeMore: VFC<Props> = ({
  expanded,
  setExpanded,
  blackTheme = false,
}) => {
  const { t } = useTranslation()
  if (blackTheme) {
    return (
      <Typography.Text
        style={{
          lineHeight: 1.5,
          textDecoration: 'underline',
          color: colors.black,
          letterSpacing: 0.8,
          fontWeight: 500,
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? t('閉じる') : t('もっと見る')}
      </Typography.Text>
    )
  }

  return (
    <Typography.Text
      style={{
        marginTop: '16px',
        lineHeight: 1,
        color: colors.grey30,
        letterSpacing: 0.8,
        fontWeight: 700,
        cursor: 'pointer',
      }}
      onClick={() => setExpanded(!expanded)}
    >
      {expanded ? t('閉じる') : t('もっと見る')}
    </Typography.Text>
  )
}
