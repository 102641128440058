import api from 'api'
import useSWR from 'swr'

/**
 * Eventに表示するアーティストを取得するSWR
 */

export const useArtistNames = () => {
  const path = '/artists/names?limit=200'
  return useSWR<ArtistNameType[]>(
    path,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
