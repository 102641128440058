import api from 'api'
import useSWR from 'swr'

/**
 * ギャラリー詳細に表示するアーティストを取得するSWR
 */

export const useArtistsByOwnerId = (ownerId?: string) => {
  const path = ownerId ? `/owners/${ownerId}/artists?limit=100` : null
  return useSWR<ArtistNameType[]>(
    path,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
