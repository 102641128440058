import api from 'api'
import useSWR from 'swr'

/**
 * Homeに表示するギャラリーを取得するSWR
 */
export const useHomeOwners = () => {
  return useSWR<OwnerCardType[]>(
    '/home/owners?limit=10',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
