import { Typography } from 'antd'
import colors from 'helpers/colors'
import Link from 'next/link'
import ArrowRightIcon from 'public/icons/arrow/next.svg'
import { Fragment, VFC } from 'react'

type Props = {
  breadcrumbs: Array<{
    text: string
    href?: string
  }>
}

export const BreadcrumbsForLp: VFC<Props> = ({ breadcrumbs }) => {
  return (
    <Typography.Text
      style={{
        lineHeight: 1,
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
      }}
    >
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <Fragment key={index}>
            {index > 0 && (
              <ArrowRightIcon style={{ height: '10px', width: '10px' }} />
            )}
            {breadcrumb.href ? (
              <Link href={breadcrumb.href}>
                <a style={{ textDecoration: 'underline' }}>
                  <Typography.Text
                    style={{ fontSize: 14, color: colors.black }}
                  >
                    {breadcrumb.text}
                  </Typography.Text>
                </a>
              </Link>
            ) : (
              <Typography.Text style={{ fontSize: 14, fontWeight: 700 }}>
                {breadcrumb.text}
              </Typography.Text>
            )}
          </Fragment>
        )
      })}
    </Typography.Text>
  )
}
