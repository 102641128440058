/**
 * 言語に応じて説明を返す関数
 */
export const getArtistDescription = (
  artist: Pick<Artist, 'description' | 'description_en'>,
  langType: string,
): string | undefined => {
  if (langType === 'en' && artist?.description_en) return artist.description_en
  // フォールバック先は description
  return artist.description
}
