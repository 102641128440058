import api from 'api'
import useSWR from 'swr'

/**
 * Homeに表示するイベントを取得するSWR
 */
export const useHomeEvents = () => {
  return useSWR<EventCardType[]>(
    '/home/events?limit=10',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
