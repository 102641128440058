import URL from 'url-parse'

import { isValidUrl } from './isValidUrl'

const url = new URL(process.env.NEXT_PUBLIC_ROOT_DOMAIN as string)

/**
 * domain に基づいて適切な url を返す。domain を指定しない場合は共通のurlを返す
 * @param {string} pathname
 * @param {string} domain
 * @returns {string}
 */
export const generateUrl = (pathname = '/', domain?: string): string => {
  const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'

  // 指定がない場合は共通のurl
  if (!domain) {
    return `${process.env.NEXT_PUBLIC_ROOT_DOMAIN as string}${pathname}`
  }

  // 独自ドメインの場合
  if (isValidUrl(domain)) {
    return `${protocol}://${domain}${pathname}`
  }

  // サブドメインの場合
  return `${protocol}://${domain}.${url.host}${pathname}`
}
