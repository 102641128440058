import moment from 'moment'

type Arg = (
  | {
      // startかendどちらかの指定は必要
      start: string
      end?: string
    }
  | {
      start?: string
      end: string
    }
) & {
  format?: string
  formatExceptYear?: string
  connectingText?: string
}

const DEFAULT_FORMAT = 'YYYY.M.D'
const DEFAULT_FORMAT_EXCEPT_YEAR = 'M.D'

/**
 * 同年の場合は年を省略した期間を生成する関数
 * @returns string
 * ex) 2022.10.14 ~ 12.14
 */
export const generateDateTerm = ({
  start,
  end,
  format = DEFAULT_FORMAT,
  formatExceptYear = DEFAULT_FORMAT_EXCEPT_YEAR,
  connectingText = '~',
}: Arg) => {
  if (!start) return `${connectingText} ${moment(end).format(format)}`

  if (!end) return `${moment(start).format(format)} ${connectingText}`

  if (moment(start).isSame(end, 'year')) {
    return `${moment(start).format(format)} ${connectingText} ${moment(
      end,
    ).format(formatExceptYear)}`
  }

  return `${moment(start).format(format)} ${connectingText} ${moment(
    end,
  ).format(format)}`
}
