import { Typography } from 'antd'
import { Breadcrumbs } from 'components/shared/Breadcrumbs'
import colors from 'helpers/colors'
import { urls } from 'helpers/urls'
import {
  Article,
  Entry,
  generateReactComponentFormRichTextDocument,
} from 'libs/contentfulForMagazine'
import moment from 'moment'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { VFC } from 'react'
import styled from 'styled-components'

import { ShareButtons } from './ShareButtons'

type Props = {
  item: Entry<Article>
  xs?: boolean
}

export const ArticlePage: VFC<Props> = ({ item, xs }) => {
  const CoverHeight = xs ? '484px' : '671px'
  const category = item.metadata?.tags.at(0)?.sys.id || ''
  const { t } = useTranslation()

  return (
    <>
      <div
        className="Cover Wrap"
        style={{
          position: 'relative',
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CoverDiv
          style={{
            backgroundImage: `url('${item.fields.mainImage?.fields.file.url}')`,
            backgroundColor: colors.grey30,
            height: CoverHeight,
          }}
        />

        <div
          className="Cover Base"
          style={{
            position: 'relative',
            zIndex: 2,
            display: 'flex',
            flexDirection: 'column-reverse',
            alignSelf: 'stretch',
            height: CoverHeight,
            width: '100vw',
            margin: '0 calc(50% - 50vw)',
          }}
        >
          <div
            className="Cover Texts"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              padding: '40px 24px 24px 24px',
              gap: '4px',
              background:
                'linear-gradient(0deg, rgba(35, 36, 39, 0.90) 0%, rgba(35, 36, 39, 0.00) 100%)',
            }}
          >
            <Typography.Title
              level={2}
              style={{
                color: 'white',
              }}
            >
              {item.fields.title}
            </Typography.Title>
            <Link
              href={{ pathname: urls.magazines, query: { category: category } }}
            >
              <a
                style={{
                  color: 'white',
                }}
              >
                {t(category)}
              </a>
            </Link>
          </div>
        </div>

        <div
          className="Contents"
          style={{
            display: 'flex',
            padding: xs ? '24px' : '40px 24px 0px 24px',
            flexDirection: 'column',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        >
          <div
            className="InnerContents"
            style={{
              display: 'flex',
              width: xs ? '100%' : '720px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              wordBreak: 'break-word',
            }}
          >
            <div
              className="AuthorDate"
              style={{
                display: 'flex',
                flexDirection: xs ? 'column' : 'row',
                paddingBottom: '8px',
                borderBottom: '1px solid var(--ax-black, #232427)',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                gap: '4px',
              }}
            >
              <Typography.Text>By {item.fields.author}</Typography.Text>
              <Typography.Text>
                {moment(item.fields.publishedAt).format('YYYY.MM.DD')} Update
              </Typography.Text>
            </div>

            {item.fields.description && (
              <div
                className="Heading"
                style={{
                  display: 'flex',
                  padding: '24px 0px',
                  alignItems: 'center',
                  gap: '16px',
                  alignSelf: 'stretch',
                }}
              >
                <div
                  className="Bar"
                  style={{
                    width: '4px',
                    borderRadius: '50px',
                    border: '1px solid #000',
                    background: 'var(--ax-black, #232427)',
                    alignSelf: 'stretch',
                  }}
                />
                <Typography.Text
                  style={{ flex: '1 0 0', whiteSpace: 'pre-line' }}
                >
                  {item.fields.description}
                </Typography.Text>
              </div>
            )}

            <div className="ArticleBody">
              {generateReactComponentFormRichTextDocument(
                item.fields.contents,
                xs,
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          paddingTop: xs ? '80px' : '120px',
        }}
      />
      <ShareButtons
        slug={item.fields.slug}
        url={`https://artx.com/magazines/${item.fields.slug}`}
        title={item.fields.title}
        xs={xs}
      />
      <Breadcrumbs
        breadcrumbs={[
          { text: t('マガジン'), href: urls.magazines },
          { text: t(category || 'all'), href: urls.magazine(category) },
          { text: item.fields.title },
        ]}
        xs={xs}
      />
    </>
  )
}

const CoverDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  margin: 0 calc(50% - 50vw);
  background-size: cover;
  background-position: center;
`
