export const ART_WORK_SEARCH_KEY = {
  keyword:
    'art_work_title_or_art_work_owner_name_or_art_work_artists_name_or_art_work_artists_name_en_cont_any',
  artist: 'art_work_artists_id_in',
  classification: 'art_work_classification_id_in',
  technique: 'art_work_techniques_id_in',
  minPrice: 'price_gteq',
  maxPrice: 'price_lteq',
}

export const EVENT_SEARCH_KEY = {
  keyword:
    'title_or_owner_name_or_event_places_name_or_event_places_city_or_event_places_state_cont_any',
  event_places: 'event_places_state_or_event_places_city_cont_any',
  start_at: 'start_at_lteq_any',
  end_at: 'end_at_gteq_any',
}

export const EVENT_CHECKIN_SEARCH_KEY = {
  checked_in_at_date: 'checked_in_at_date_eq',
  event_title_cont: 'event_title_cont',
}

export const OWNER_SEARCH_KEY = {
  name_cont_any: 'name_cont_any',
  owner_address: 'owner_addresses_state_or_owner_addresses_city_cont_any',
}
