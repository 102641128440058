import { message } from 'antd'
import api from 'api'
import { useAuth } from 'contexts/auth'
import colors from 'helpers/colors'
import { FollowingArticle } from 'libs/contentfulForMagazine'
import { useTranslation } from 'next-i18next'
import HeartIcon from 'public/icons/heart.svg'
import { CSSProperties, useEffect, useState, VFC } from 'react'

type Props = {
  style?: CSSProperties
  followStatus: FollowingArticle
}

export const FollowButtonS: VFC<Props> = ({ followStatus, style }) => {
  const { t } = useTranslation()
  const { slug, follow_status } = followStatus
  const { isAuthenticated, setIsSignInOpened, setSignInNotification } =
    useAuth()
  const [isFollow, setIsFollow] = useState(follow_status)

  useEffect(() => {
    setIsFollow(follow_status)
  }, [follow_status])

  const onClickFollowButton = async () => {
    if (!isAuthenticated) {
      setIsSignInOpened(true)
      setSignInNotification(t('お気に入りを利用するには、ログインが必要です。'))
      return
    }

    if (isFollow) {
      const { data, error }: { data: any; error: any } = await api.delete(
        `/magazines/${slug}/unfollow`,
      )
      if (data) {
        message.success(t('お気に入りを解除しました'))
        setIsFollow(false)
      }
      if (error) {
        message.error(error.data.error)
      }
    } else {
      const { data, error }: { data: any; error: any } = await api.post(
        `/magazines/${slug}/follow`,
      )
      if (data) {
        message.success(t('お気に入りに追加しました'))
        setIsFollow(true)
      }
      if (error) {
        message.error(error.data.error)
      }
    }
  }

  return (
    <HeartIcon
      style={{
        ...style,
        color: isFollow ? colors.blue : colors.black,
        opacity: isFollow ? 1 : 0.6,
        width: '24px',
        height: '24px',
      }}
      onClick={(e) => {
        e.preventDefault()
        onClickFollowButton()
      }}
    />
  )
}
