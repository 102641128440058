import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { ImageViewer } from 'components/shared/ImageViewer'
import { getArtWorkDescription } from 'libs/artWorks/getArtWorkDescription'
import { useMemo, VFC } from 'react'

import { ArtistDescription, ArtworkDescription } from './parts'

type ArtWorkType = ArtWork & {
  art_work_images: ArtWorkImage[]
  owner: Owner
  classification: Classification
  techniques: Technique[]
}

type Props = {
  artWork: ArtWorkType
  artist: Artist
  language: string
}

export const ArtWorkInformation: VFC<Props> = ({
  artWork,
  artist,
  language,
}) => {
  const { xs } = useBreakpoint()

  const artWorkDescription = useMemo(
    () => getArtWorkDescription(artWork, language),
    [artWork, language],
  )

  return (
    <>
      <ImageViewer images={artWork.art_work_images} />
      {!xs && (
        <>
          {artWorkDescription && (
            <ArtworkDescription description={artWorkDescription} />
          )}
          <ArtistDescription artist={artist} />
        </>
      )}
    </>
  )
}
