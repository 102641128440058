import api from 'api'
import useSWR from 'swr'

/**
 * 分類を取得するSWR
 */
export const useClassifications = () => {
  return useSWR<Classification[]>(
    '/static/art_work_classifications',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
