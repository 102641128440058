import { Typography } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { urls } from 'helpers/urls'
import { ArticleCard, Entry } from 'libs/contentfulForMagazine'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

export const MagazineContent: FC<{
  magazineForDisplay: Entry<ArticleCard>
}> = ({ magazineForDisplay }) => {
  const { xs } = useBreakpoint()
  const categories = magazineForDisplay.metadata.tags.map((tag) => tag.sys.id)
  const { t } = useTranslation()
  const imageQueries = xs ? 'fit=thumb&h=570' : 'fit=thumb'
  const imageUrl = `${magazineForDisplay.fields.mainImage?.fields.file.url}?${imageQueries}`

  return (
    <Link
      href={urls.magazine(magazineForDisplay.sys.id)}
      as={urls.magazine(magazineForDisplay.fields.slug)}
      passHref
    >
      <div
        style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          height: xs ? 569 : 500,
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div
          style={{
            padding: '40px 24px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '4px',
            marginTop: 'auto',
            zIndex: 1,
            background:
              'linear-gradient(to bottom, rgba(35, 36, 39, 0), rgba(35, 36, 39, 0.9))',
          }}
        >
          <Typography.Text
            style={{ fontSize: 14, lineHeight: 1, color: 'white' }}
          >
            {t('マガジン')}
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: 20,
              lineHeight: 1.5,
              fontWeight: 400,
              color: 'white',
            }}
          >
            {magazineForDisplay.fields.title}
          </Typography.Text>
          {categories.map((category) => {
            return (
              <Link
                key={category}
                href={{ pathname: urls.magazines, query: { category } }}
              >
                <a>
                  <Typography.Text
                    style={{
                      fontSize: 14,
                      lineHeight: 1,
                      color: 'white',
                      marginRight: 4,
                    }}
                  >
                    {t(category)}
                  </Typography.Text>
                </a>
              </Link>
            )
          })}
        </div>
      </div>
    </Link>
  )
}
