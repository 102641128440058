import api from 'api'
import useSWR from 'swr'

/**
 * カート情報を取得するSWR
 */

// TODO: 削除予定
export const useCartOrder = () => {
  return useSWR<Order>(
    '/orders/cart',
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
