import { Dropdown } from 'antd'
import { useAuth } from 'contexts/auth'
import { ComponentProps, FC, useMemo } from 'react'

import { AccountMenuItem } from './AccountMenuItem'

type Props = {
  children: React.ReactNode
  placement?: ComponentProps<typeof Dropdown>['placement']
}

export const AccountMenuDropdown: FC<Props> = ({
  children,
  placement = 'bottomRight',
}) => {
  const { user } = useAuth()

  const LoggedInMenu = useMemo(
    () => (
      <AccountMenuItem
        user={user}
        style={{ width: '314px', padding: '16px 24px' }}
      />
    ),
    [user],
  )

  return (
    <Dropdown trigger={['click']} overlay={LoggedInMenu} placement={placement}>
      {children}
    </Dropdown>
  )
}
