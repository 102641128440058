import { Typography } from 'antd'
import colors from 'helpers/colors'
import { generatePriceText } from 'libs/generatePriceText'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type Props = {
  artWork: ArtWorkCardType
}

/**
 * NOTE: 作品在庫が全て売り切れもの場合のみ、SOLD OUTのラベルを表示している。
 */
export const ArtWorkPriceLabel: FC<Props> = ({ artWork }) => {
  const minPriceArtWorkVarianObject = {
    currency: artWork?.publish_currency || '',
    unit_price: artWork?.publish_price,
    is_available_online: typeof artWork?.publish_price === 'number',
  }

  const { i18n } = useTranslation()

  if (artWork.is_sold_out) {
    return (
      <div
        style={{
          padding: '4px 8px',
          width: '85px',
          backgroundColor: colors.grey30,
        }}
      >
        <p
          style={{
            color: colors.white,
            textAlign: 'center',
            fontSize: 12,
            lineHeight: 1,
            height: '14px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          SOLD OUT
        </p>
      </div>
    )
  }

  return (
    <Typography.Text
      ellipsis
      style={{ display: 'block', margin: 0, fontSize: 12, lineHeight: 1.5 }}
    >
      {generatePriceText({
        currency: minPriceArtWorkVarianObject.currency,
        publish_price: minPriceArtWorkVarianObject.unit_price,
        is_available_online: minPriceArtWorkVarianObject.is_available_online,
        language: i18n.language,
      })}
    </Typography.Text>
  )
}
