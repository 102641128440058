import api from 'api'
import useSWR from 'swr'

/**
 * エントリーを取得するSWR
 */
export const useLotteriesByArtworkVariantId = (
  artworkVariantId: string | undefined,
) => {
  return useSWR<Lottery[]>(
    artworkVariantId
      ? `/art_work_variants/${artworkVariantId}/active_lotteries`
      : null,
    (key) => api.get(key).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
