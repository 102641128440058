import { Typography } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { NoImageDiv } from 'components/shared/NoImageDiv'
import colors from 'helpers/colors'
import { urls } from 'helpers/urls'
import { getArtWorkTitle } from 'libs/artWorks/getArtWorkTitle'
import Image from 'next/future/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { CSSProperties, useMemo, VFC } from 'react'

import { FollowButtonS } from '../FollowButton'
import { ArtWorkInquiryLabel } from './parts/ArtWorkInquiryLabel'
import { ArtWorkPriceLabel } from './parts/ArtWorkPriceLabel'

type Props = {
  artWork?: ArtWorkCardType
  style?: CSSProperties
  initialFollowStatus?: boolean
  sideBySide?: boolean
}

// const sizeWidthMap = {
//   small: 180,
//   medium: 280,
//   large: "100%",
// }

// const sizeHeightMap = {
//   small: 220,
//   medium: 340,
//   large: "100%",
// }

export const ArtWorkCard: VFC<Props> = ({ artWork, style, sideBySide }) => {
  const { artists, owner, main_image_thumb_contain_url } = artWork || {}

  // const isStocked = artWorkVariants.some((artWorkVariant) => artWorkVariant.quantity && artWorkVariant.quantity > 0)
  const { xs } = useBreakpoint()
  const { i18n } = useTranslation()
  const language = useMemo(() => i18n.language, [i18n.language])

  const artistText = useMemo(() => {
    if (!artists) return ''
    const artistName = (artist: Artist) => {
      if (language === 'en' && artist.name_en) return artist.name_en
      return artist.name
    }

    return artists?.map((artist) => artistName(artist)).join('｜ ')
  }, [artists, language])

  if (!artWork) {
    return null
  }

  return (
    <div
      style={{ width: xs ? '100%' : 270, minWidth: xs ? 159 : 270, ...style }}
    >
      <Link href={urls.art_work(artWork.id)} passHref>
        <a title={getArtWorkTitle(artWork, i18n.language)}>
          <div
            style={{
              alignItems: 'flex-end',
              display: 'flex',
              marginBottom: 12,
              position: 'relative',
            }}
          >
            {main_image_thumb_contain_url ? (
              <Image
                src={main_image_thumb_contain_url}
                style={{
                  objectFit: 'contain',
                  objectPosition: '50% 100%',
                  width: '100%',
                  height: 'auto',
                  maxHeight: sideBySide ? 420 : undefined,
                }}
                alt={getArtWorkTitle(artWork, i18n.language)}
                sizes={xs ? '50vw' : '20vw'}
              />
            ) : (
              <div style={{ width: xs ? '100%' : 270, aspectRatio: '3 / 4' }}>
                <NoImageDiv type="artwork" />
              </div>
            )}
            <FollowButtonS
              style={{ position: 'absolute', right: 8, bottom: 8 }}
              artWork={artWork}
            />
          </div>
          <div
            style={{
              maxWidth: xs ? 164 : undefined,
              display: 'flex',
              flexDirection: 'column',
              rowGap: '4px',
              height: sideBySide ? (xs ? 120 : 102) : 'auto',
            }}
          >
            {artists && (
              <Typography.Title
                ellipsis={{ rows: 2 }}
                style={{
                  fontWeight: 'bold',
                  fontSize: 12,
                  lineHeight: artists.length > 1 ? 1.5 : 1,
                }}
              >
                {artistText}
              </Typography.Title>
            )}
            <Typography.Title
              level={3}
              ellipsis={{ rows: 2 }}
              style={{
                margin: 0,
                fontSize: 14,
                lineHeight: 1.5,
                fontWeight: 'normal',
              }}
            >
              {getArtWorkTitle(artWork, i18n.language)}
            </Typography.Title>
            {owner?.name && (
              <Link href={urls.owner(owner?.id || '')} passHref>
                <a style={{ display: 'flex' }}>
                  <Typography.Text
                    ellipsis
                    style={{
                      color: colors.grey30,
                      margin: 0,
                      fontSize: 12,
                      lineHeight: 1.5,
                    }}
                  >
                    {owner.name}
                  </Typography.Text>
                </a>
              </Link>
            )}
            {/* 現状で公開かつpriceが返らないものは全てask */}
            {!artWork.publish_price && !artWork.is_sold_out && (
              <ArtWorkInquiryLabel />
            )}
            <ArtWorkPriceLabel artWork={artWork} />
          </div>
        </a>
      </Link>
    </div>
  )
}
