import api from 'api'
import { FollowingArticle } from 'libs/contentfulForMagazine'
import useSWR from 'swr'

/**
 * 複数のslugをもとに記事がお気に入り登録されてるか取得するSWR
 */

export const useIsFollowings = (slugs: string[]) => {
  return useSWR<FollowingArticle[]>(
    slugs ? ['/magazines/followings', slugs] : null,
    (key) =>
      api
        .get(key, {
          params: { slugs: slugs },
        })
        .then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
