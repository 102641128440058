import api from 'api'
import useSWR from 'swr'

export const useCheckedInDays = (date: string) => {
  const params = { date: date }
  return useSWR<number[]>(
    ['/users/event_checkin_days', params],
    (key, params) => api.get(key, { params }).then((res) => res.data),
    {
      revalidateOnFocus: false,
    },
  )
}
