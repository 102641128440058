import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import colors from 'helpers/colors'
import { throttle } from 'lodash'
import ArrowNextIcon from 'public/icons/arrow/next.svg'
import ArrowPrevIcon from 'public/icons/arrow/prev.svg'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export const List: FC = ({ children }) => {
  const listRef = useRef<HTMLDivElement>(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [listRefWidth, setListRefWidth] = useState(1120)

  const { xs } = useBreakpoint()

  const scrollListHandler = throttle(() => {
    if (!listRef.current) return

    setScrollLeft(listRef.current.scrollLeft)
    setListRefWidth(listRef.current.scrollWidth - listRef.current.clientWidth)
  }, 100)

  useEffect(() => {
    if (!listRef.current) return

    listRef.current.addEventListener('scroll', scrollListHandler)
  }, [scrollListHandler])

  const handleClickPrev = useCallback(() => {
    if (!listRef.current) return

    listRef.current.scroll({
      left: listRef.current.scrollLeft - listRefWidth,
      behavior: 'smooth',
    })
  }, [listRefWidth])

  const handleClickNext = useCallback(() => {
    if (!listRef.current) return

    listRef.current.scroll({
      left: listRef.current.scrollLeft + listRefWidth,
      behavior: 'smooth',
    })
  }, [listRefWidth])

  return (
    <StyledDiv style={{ position: 'relative' }}>
      <StyledImageWrapper
        style={{
          position: 'relative',
          display: 'flex',
          gap: '24px',
          overflowX: 'auto',
          padding: xs ? '24px 64px 0px' : '24px 60px 0px',
          alignItems: 'end',
          // スクロールできる要素を表示するために左右に余白を設ける
          margin: '0 -64px',
        }}
        ref={listRef}
      >
        {children}
      </StyledImageWrapper>
      {!xs && (
        <ArrowButton
          className="arrow-button"
          style={{
            left: '-25px',
            top: 'calc(50% - 48px)',
            position: 'absolute',
            transform: 'translateY(-10px)',
          }}
          onClick={() => handleClickPrev()}
          disabled={scrollLeft === 0}
        >
          <ArrowPrevIcon
            style={{ height: '20px', width: '20px', cursor: 'pointer' }}
          />
        </ArrowButton>
      )}
      {!xs && (
        <ArrowButton
          className="arrow-button"
          style={{
            right: '-25px',
            top: 'calc(50% - 48px)',
            position: 'absolute',
            transform: 'translateY(-10px)',
          }}
          onClick={() => handleClickNext()}
          disabled={scrollLeft === listRefWidth}
        >
          <ArrowNextIcon
            style={{ height: '20px', width: '20px', cursor: 'pointer' }}
          />
        </ArrowButton>
      )}
    </StyledDiv>
  )
}

const StyledImageWrapper = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
`

const ArrowButton = styled.button`
  width: 48px;
  height: 48px;
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: 50%;
  padding: 13px;
  opacity: 1;
  font-size: 17px;
  z-index: 1;
  cursor: pointer;
  &:hover {
    background-color: ${colors.black};
    border: 1px solid ${colors.white};
    color: ${colors.white};
  }
  &:disabled {
    background-color: ${colors.grey};
    border: 1px solid ${colors.grey30};
    color: ${colors.grey30};
    opacity: 1;
  }
`

const StyledDiv = styled.div`
  .arrow-button {
    opacity: 0;
    transition: 0.4s;
  }
  :hover {
    .arrow-button {
      opacity: 1;
      transition: 0.4s;
    }
  }
`
