/**
 * 言語に応じて分類を返す関数
 */
export const getClassificationName = (
  classification: Classification,
  langType: string,
): string | undefined => {
  if (langType === 'en' && classification?.name_en)
    return classification?.name_en
  // フォールバック先は name
  return classification?.name
}
